import client from 'libs/HttpClient';
class SettingService {
  static getSettingData() {
    return client.get('setting/get');
  }
  static getAllSettingData() {
    return client.get('setting/all-data');
  }
  static storeSettingData(request) {
    return client.post('setting/store', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getMailNotificationData() {
    return client.get('setting/mail-notification');
  }
  static storeMailNotificationData(request) {
    return client.post('setting/mail-notification/store', request);
  }
  static getHomeSettingData(params) {
    return client.get('setting/home-settings', { params });
  }
  static storeHomeSeoSettingData(request) {
    return client.post('setting/home-seo', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeHomeJobPostSettingData(request) {
    return client.post('setting/home-job-post', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }

  static storeHomeBannerSettingData(request) {
    return client.post('setting/home-banner', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeHomeFooterSettingData(request) {
    return client.post('setting/home-footer', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeHomeDownloadSettingData(request) {
    return client.post('setting/home-download', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getAboutSettingData(params) {
    return client.get('setting/about-settings', { params });
  }
  static storeAboutSettingData(request) {
    return client.post('setting/about-setting', request);
  }
  static storeAboutSeoSettingData(request) {
    return client.post('setting/about-seo', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeAboutWhoWeAreSettingData(request) {
    return client.post('setting/about-who-are-we', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeAboutOurMissionSettingData(request) {
    return client.post('setting/about-our-mission', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeAboutOurVisionSettingData(request) {
    return client.post('setting/about-our-vision', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static storeAboutOurTeamSettingData(request) {
    return client.post('setting/about-our-team', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getContactSettingData(params) {
    return client.get('setting/contact-us-settings', { params });
  }
  static storeContactSettingData(request) {
    return client.post('setting/contact-us-setting', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
  static getFaqSettingData(params) {
    return client.get('setting/faq-settings', { params });
  }
  static storeFaqSettingData(request) {
    return client.post('setting/faq-settings', request, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });
  }
}

export { SettingService };
