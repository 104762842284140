import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loggedUser } from 'store';
import { requestForToken, onMessageListener } from './firebase';
import { client } from 'libs';

const Notification = () => {
  const checkLoggedInUser = useSelector(loggedUser);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast.info(<ToastDisplay />);
  const ToastDisplay = () => {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
    if (checkLoggedInUser.isAdminLoggedIn) requestForToken();
  }, [notification, checkLoggedInUser]);
  onMessageListener()
    .then((payload) => {
      const { notification } = payload;
      client.post(`/notifications/add`, notification);
      // setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
    })
    .catch((err) => console.log('failed: ', err));
};

export default Notification;
