import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './features/authSlice'; // Adjusted import for authReducer
import loaderReducer from './features/loaderSlice';
import settingReducer from './features/settingSlice';
import notificationReducer from './features/notificationSlice';

const persistConfig = {
  key: 'collegely-admin',
  storage,
  blacklist: [], // Adjusted as needed
};

const rootReducer = combineReducers({
  user: authReducer,
  loader: loaderReducer,
  setting: settingReducer,
  notifications: notificationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for react-query compatibility
    }),
  devTools: true, // Enable Redux DevTools extension
});

let persistor = persistStore(store);
export { persistor, store };
export {
  loginSuccess,
  logoutSuccess,
  updateUserData,
  updateToken,
  loggedUser,
  authSlice,
  loaderSlice,
  showLoader,
  hideLoader,
  loader,
  settingsSlice,
  addSetting,
  clearSetting,
  setSettings,
  updateSettings,
  settingData,
  notificationSlice,
  setNotifications,
  addNotification,
} from './features';
