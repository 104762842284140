import React from 'react';
import { Link } from 'react-router-dom';

import { TRANSLATIONS, LIST, FAQ, EMAIL_TEMPLATE, CMS, FAQ_CATEGORY, TEAM_MEMBER } from 'config';

const SidebarMastersLinks = (props) => {
  const { t } = props;
  const cmsClass = location.pathname.match(/^\/cms/) ? 'active' : '';
  const translationsClass = location.pathname.match(/^\/translations/) ? 'active' : '';
  const emailTemplateClass = location.pathname.match(/^\/email-template/) ? 'active' : '';
  const faqCategoryClass = location.pathname.startsWith('/faq-category/') ? 'active' : '';
  const faqClass = location.pathname.startsWith('/faq/') ? 'active' : '';
  const teamMemberClass = location.pathname.startsWith('/team-member/') ? 'active' : '';

  return (
    <>
      <ul className={`submenu collapse ${props.master ? 'show' : ''}`}>
        <li>
          <Link
            className={cmsClass}
            to={CMS + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_cms')}
          </Link>
        </li>
        <li>
          <Link
            className={translationsClass}
            to={TRANSLATIONS + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_translations')}
          </Link>
        </li>

        <li>
          <Link
            className={emailTemplateClass}
            to={EMAIL_TEMPLATE + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_email_templates')}
          </Link>
        </li>
        <li>
          <Link
            className={faqCategoryClass}
            to={FAQ_CATEGORY + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_faq_category')}
          </Link>
        </li>
        <li>
          <Link
            className={faqClass}
            to={FAQ + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_faq')}
          </Link>
        </li>
        <li>
          <Link
            className={teamMemberClass}
            to={TEAM_MEMBER + LIST}
            onClick={() => {
              props.toggleClass(props.active);
            }}>
            {t('page.sidebar_team_member')}
          </Link>
        </li>
      </ul>
    </>
  );
};

export { SidebarMastersLinks };
