import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import {
  useGetAllSettingData,
  useListEmailTemplateWithoutPagination,
  useStoreSettingData,
} from 'hooks';
import { NavItemsCommon } from 'common';
import { mailNotificationNavItems, DASHBOARD } from 'config';

const GeneralMailNotificationsSettings = ({ t }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const { refetch: refetchMailNotifications } = useGetAllSettingData((res) => {
    setSettings(res.data);
  });

  const { refetch: refetchEmailTemplates } = useListEmailTemplateWithoutPagination((res) => {
    setEmailTemplates(res.data.email_template_list);
  });

  useEffect(() => {
    refetchMailNotifications();
    refetchEmailTemplates();
  }, [refetchMailNotifications, refetchEmailTemplates]);

  useEffect(() => {
    if (Object.keys(settings).length > 0 && emailTemplates.length > 0) {
      const newInitialValues = {};

      emailTemplates.forEach((template) => {
        const isEnabled = settings[template.slug] === '1' ? '1' : '2';
        newInitialValues[template.slug] = isEnabled;
      });

      newInitialValues.master_email_enable = settings.master_email_enable === '1' ? '1' : '2';
      newInitialValues.master_notification_enable =
        settings.master_notification === '1' ? '1' : '2';

      newInitialValues.sub_email = '2';

      setInitialValues(newInitialValues);
    }
  }, [settings, emailTemplates]);

  const { mutate: doStoreSettingData } = useStoreSettingData((response) => {
    toast.success(response.message);
  });

  const areAllTemplatesEnabled = (values) => {
    return emailTemplates.every((template) => values[template.slug] === '1');
  };

  if (Object.keys(initialValues).length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavItemsCommon navItemName={mailNotificationNavItems} t={t} />
      <section id="content-wrapper">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tab_panel"
            aria-labelledby="pills-home-tab">
            <div className="inner-box table-responsive mail-notification-table">
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  const formattedObject = Object.entries(values).reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                  }, {});
                  doStoreSettingData(formattedObject);
                }}>
                {({ values, setFieldValue }) => {
                  useEffect(() => {
                    const allEnabled = areAllTemplatesEnabled(values);
                    setFieldValue('sub_email', allEnabled ? '1' : '2');
                  }, [values, emailTemplates]);
                  const excludedTemplates = [
                    'contact-us',
                    'faq-help-question',
                    'email-verification',
                    'forgot-password',
                    'resend-otp',
                  ];
                  const filteredEmailTemplates = emailTemplates.filter(
                    (template) => !excludedTemplates.includes(template.slug)
                  );
                  return (
                    <Form style={{ maxWidth: '100%' }} id="general-project-mail">
                      <div className="table-responsive heading-toggle d-flex mt-2 justify-content-end">
                        <div className="d-flex mx-3 align-items-center justify-content-center">
                          <label>{t('page.general_settings_email_title')}</label>
                          <label className="switch email-switch ms-2">
                            <Field
                              type="checkbox"
                              name="master_email_enable"
                              checked={values.master_email_enable === '1'}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                setFieldValue('master_email_enable', checked ? '1' : '2');
                                setFieldValue('sub_email', checked ? '1' : '2');
                                emailTemplates.forEach((template) => {
                                  setFieldValue(template.slug, checked ? '1' : '2');
                                });
                              }}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="d-flex mx-3 align-items-center justify-content-center">
                          <label>{t('page.general_settings_notification_title')}</label>
                          <label className="switch notification-switch ms-2">
                            <Field
                              type="checkbox"
                              name="master_notification_enable"
                              checked={values.master_notification_enable === '1'}
                              onChange={(e) =>
                                setFieldValue(
                                  'master_notification_enable',
                                  e.target.checked ? '1' : '2'
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>

                      {/* Email templates table */}
                      <div className="table-responsive blog-table mt-2">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                {t('page.general_settings_project_notification_common_title')}
                              </th>
                              <th>
                                <div className="d-flex align-items-center justify-content-center">
                                  <label>{t('page.general_settings_email_title')}</label>
                                  <label className="switch email-switch ms-2">
                                    <Field
                                      type="checkbox"
                                      name="sub_email"
                                      checked={values.sub_email === '1'} // Ensure checkbox reflects "1" for checked
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        setFieldValue('sub_email', checked ? '1' : '2');
                                        emailTemplates.forEach((template) => {
                                          setFieldValue(template.slug, checked ? '1' : '2');
                                        });
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredEmailTemplates.map((template) => (
                              <React.Fragment key={template.email_template_id}>
                                <tr>
                                  <td>
                                    {template.title || t('page.general_settings_active_user')}
                                  </td>
                                  <td>
                                    <label className="email-switch switch">
                                      <Field
                                        type="checkbox"
                                        name={template.slug} // Using title directly as name
                                        checked={values[template.slug] === '1'} // Ensure checkbox reflects "1" for checked
                                        onChange={(e) => {
                                          const checked = e.target.checked;
                                          setFieldValue(template.slug, checked ? '1' : '2');
                                        }}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="primary-button">
                        <button
                          className="btn btn-primary bt-send mx-2"
                          type="button"
                          onClick={() => navigate(DASHBOARD)}>
                          {t('page.cancel_btn_text')}
                        </button>
                        <button className="btn btn-primary bt-send mx-2" type="submit">
                          {t('page.submit_btn_text')}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { GeneralMailNotificationsSettings };
