import React from 'react';
import { NavLink } from 'react-router-dom';

import {
  SETTING_HOME,
  HOME_BANNER,
  HOME_DOWNLOAD,
  HOME_FOOTER,
  HOME_JOB_POST,
  HOME_SEO,
  SETTING_ABOUT,
  ABOUT_BANNER,
  ABOUT_MISSION,
  ABOUT_OUR_TEAM,
  ABOUT_SEO,
  ABOUT_VISION,
  ABOUT_WHO_WE_ARE,
  SETTING,
  GENERAL,
  SETTING_CONTACT_US,
  CONTACT_SETTING,
  FAQ_SETTING,
  SETTING_FAQ,
} from 'config';

const SidebarSettingLinks = (props) => {
  const { t } = props;
  const activePaths = [SETTING_HOME + HOME_FOOTER, SETTING_HOME + HOME_SEO];
  const activeAboutPaths = [
    SETTING_ABOUT + ABOUT_MISSION,
    SETTING_ABOUT + ABOUT_OUR_TEAM,
    SETTING_ABOUT + ABOUT_SEO,
    SETTING_ABOUT + ABOUT_VISION,
  ];
  const isActive = activePaths.some((path) => location.pathname === path);
  const isAboutActive = activeAboutPaths.some((path) => location.pathname === path);
  return (
    <>
      <ul className={`submenu collapse ${props.setting ? 'show' : ''}`}>
        <li>
          <NavLink to={SETTING + GENERAL} onClick={props.toggleClass}>
            {t('page.sidebar_generals')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={SETTING_HOME + HOME_FOOTER}
            onClick={props.toggleClass}
            className={isActive ? 'active' : ''}>
            {t('page.sidebar_home_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to={SETTING_ABOUT + ABOUT_WHO_WE_ARE}
            onClick={props.toggleClass}
            className={isAboutActive ? 'active' : ''}>
            {t('page.sidebar_about_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink to={SETTING_CONTACT_US + CONTACT_SETTING} onClick={props.toggleClass}>
            {t('page.sidebar_contact_settings')}
          </NavLink>
        </li>
        <li>
          <NavLink to={SETTING_FAQ + FAQ_SETTING} onClick={props.toggleClass}>
            {t('page.sidebar_faq_settings')}
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export { SidebarSettingLinks };
